document.addEventListener("turbolinks:load", function(){
  // プロフィールの表示切り替え
  $('#js-username').on('click', function(){
    $('.profile-detail').fadeToggle();
    $('.triangle').fadeToggle();
  });

  // デッキ一覧のモーダル画面
  $('.js-modal-open').on('click',function(){
      $('.js-modal').fadeIn();
      return false;
  });
  $('.js-modal-close').on('click',function(){
      $('.js-modal').fadeOut();
      return false;
  });

  // ボタン押下の際のバリデーションチェック
  if (document.getElementById("js-validationCheck") != null) {
    document.getElementById("js-validationCheck").addEventListener("click", function (e) {
      if(document.getElementById("js-new-password") != null) {
        validationCheck(e, "js-new-password", "パスワード");
      }
      if(document.getElementById("js-password") != null) {
        validationCheck(e, "js-password", "パスワード");
      }
      if(document.getElementById("js-email-form") != null) {
        validationCheck(e, "js-email-form", "メールアドレス");
      }
      if(document.getElementById("mail_address") != null) {
        validationCheck(e, "mail_address", "メールアドレス");
      }
    });
  }

  $(function() {
  history.pushState(null, null, null);

    $(window).on("popstate", function(){
      history.pushState(null, null, null);
    });
  }); 

  // バリデーションチェックの関数
  function validationCheck(e, id, message) {
    if(document.getElementById(id).value != ""){
      if (document.getElementById(id).value.match(/^[-a-zA-Z0-9@+.!?^_#*%$]+$/) == null) {
        e.preventDefault();
        document.getElementById(id).value = "";
        alert(`エラー：${message}は半角英数字記号で記入してください。`);
      }
    }
  }

  // サブスクリプションのキャンセル処理
  if (document.querySelector('#js-release') != null) {
    document.querySelector('#js-release').addEventListener('click', async function () {
      const ok = confirm('本当に解除してよろしいですか？');
      if (ok) {
        await document.querySelector('#js-loader').classList.add('loader');
        await fetch('/cancel_subscription_order', { method: 'POST' });
        await document.querySelector('#js-loader').classList.remove('loader');
        await alert('サブスクリプションを解除しました。');
        await location.reload();
      }
    });
  }
});
